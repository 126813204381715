const NODE_ENV = process.env.NODE_ENV;

let url_api = '';
let guid = '';
let gym_id = '';

if (NODE_ENV === 'development') {
  // url_api = 'http://localhost:8080';
  guid = 'bb464ab5-559c-4408-8dce-bc2fb174ae93'
  // url_api = 'https://lsdt-api-integration.lasalledutemps.com'
  url_api = 'https://lsdt-api-production.lasalledutemps.com'
  gym_id = 1;
}
else if (NODE_ENV === 'integration') {
  // url_api = 'https://lsdt-api-integration.lasalledutemps.com'
  url_api = 'https://lsdt-api-production.lasalledutemps.com'
  // url_api = 'https://lsdt-chat-server-integ.lasalledutemps.com'
  guid = 'bb464ab5-559c-4408-8dce-bc2fb174ae93'
  gym_id = 1;

}
else if (NODE_ENV === 'production') {
  url_api = 'https://lsdt-api-production.lasalledutemps.com'
  // url_api = 'https://lsdt-api-integration.lasalledutemps.com'
  // url_api = 'https://lsdt-chat-server-prod.lasalledutemps.com'
  // url_api = 'https://lsdt-chat-server-integ.lasalledutemps.com'
  guid = 'bb464ab5-559c-4408-8dce-bc2fb174ae93'
  gym_id = 1;
}

export { url_api, guid, gym_id };